<template>
  <div v-if="deviceTemplates" class="relative max-w-7xl mx-auto px-4 py-6 pb-12 sm:px-6 lg:px-8">
    <h3 class="text-3xl font-medium leading-6 text-gray-900">
      {{ deviceTemplate.activeVersion[`${formType}FormSchema`] ? `${this.$t('template.editFormTemplate')}` : `${this.$t('template.addFormTemplate')}` }}
    </h3>
    <p class="mt-2 text-sm text-gray-400">
      {{$t("template.formDescriptionText1")}} <span class="font-medium text-gray-700">{{ formType == 'installation' ? this.$t("template.formDescriptionText2") : this.$t("template.formDescriptionText3") }}</span> {{$t("template.formDescriptionText4")}} <span class="font-medium text-gray-700">{{ deviceTemplate.nameTranslations?.[$i18n.locale] ? deviceTemplate.nameTranslations[$i18n.locale] : deviceTemplate.name }} </span> {{$t("template.formDescriptionText5")}}
    </p>
    <div class="sticky top-20 -mt-10 flex-shrink-0 relative z-10 float-right inline-flex justify-end">
      <div class="inline-flex shadow-sm rounded-md">
        <button :title="$t('template.goBackDiscard')" @click="goBack(true)" type="button" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
          <ArrowLeftIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button :title="$t('template.goBackSave')" type="button" @click="possiblyGoBack" class="relative inline-flex items-center px-2 py-2 -ml-px border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
          <DocumentArrowDownIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button :title="$t('template.clearData')" type="button" @click="clearAllData" class="relative inline-flex items-center px-2 py-2 -ml-px border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
          <ArrowPathIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button :title="$t('template.saveData')" @click="sendData" type="button" id="edit-icon" :class="['-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
          <CheckIcon class='h-5 w-5' aria-hidden="true" />
        </button>
      </div>
    </div>
    <div class="flex flex-col md:flex-row mt-16 relative">
      <div class="md:w-3/5 md:border-r md:border-gray md:pr-6 lg:pr-8">
        <div class="block text-sm font-medium text-gray-700">{{$t("template.steps")}}</div>
        <ul v-if="schema.length" role="list" class="mt-1 divide-y divide-gray-200 rounded-md shadow border border-gray-200">
          <TemplateStep
            v-for="(item, index) in schema"
            v-model="schema[index]"
            :key="index"
            :stepIndex="index"
            :schemaLength="schema.length"
            :formFields="allFields"
            :deviceFields="deviceTemplate.activeVersion.parametersSchema"
            :areFieldNamesUnique="areFieldNamesUnique"
            :mainLanguage="deviceTemplate.language"
            @move-up="moveField(index, index-1)"
            @move-down="moveField(index, index+1)"
            @delete="deleteField(index)"
            @step-click="activateStep"
          />
        </ul>
        <button
          class="block bg-ifgreen hover:bg-ifgreen-dark text-white mt-2 w-full rounded-md"
          @click="addStep"
        >
          <div class="px-3 py-3 flex justify-center">
            <PlusIcon class="h-6 w-6 mr-2" aria-hidden="true" /> {{$t("template.addStep")}}
          </div>
        </button>
      </div>
      <div class="md:w-2/5 md:pl-6 lg:pl-8">
        <div class="sticky top-20">
          <div class="block text-sm font-medium text-gray-700 pb-6 pt-2 md:pl-8 md:-ml-8 border-b border-gray-200">
            {{$t("template.formPreview")}}
          </div>
          <FormTemplateForm
            ref="formTemplateForm"
            v-model="value"
            :preview="true"
            :formType="formType"
            :schema="schema"
            :allFields="allFields"
            :props-device="device"
            @submit="submit"
            @invalid="invalid"
          />
        </div>
      </div>
    </div>
  </div>
  <Modal
    ref="modal"
    title=""
    :text="$t('settings.unfinishedConfig')"
  >
    <button
      @click="fetchLocalStorage"
      type="button"
      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm"
    >
      {{$t("restore")}}
    </button>
    <button
      @click="fetchSchema"
      type="button"
      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm"
    >
      {{$t("startNew")}}
    </button>
  </Modal>

  <Modal
    ref="copymodal"
    title=""
    :text="$t('template.newFormText')"
    :cancel-button-label="$t('template.newFormBtn1')"
  >
    <template #textarea>
      <ul class="my-4 border border-gray-400 rounded-md overflow-hidden divide-y divide-gray-300 h-36 overflow-y-scroll">
        <li v-for="(template) in availableToCopy" :key="template.name">
          <label :for="`${template.id}-${template.type}`" :class="template.recomended && 'font-medium border-b border-gray-700'" class="flex items-center justify-between w-full px-2 py-2 text-left text-xs hover:bg-gray-50">
            <span class="flex">
              <HomeIcon v-if="template.type=='installation'" class="w-4 h-4 mr-2" />
              <ClockIcon v-if="template.type=='inspection'" class="w-4 h-4 mr-2" />
              {{ template.name }}
            </span>
            <input type="radio" :id="`${template.id}-${template.type}`" :value="template" v-model="templateToCopy" name="templateToCopy" class="text-ifgreen focus:ring-ifgreen"/>
          </label>
        </li>
      </ul>
    </template>
    <button
      type="button"
      @click="copySchema"
      :class="!templateToCopy && 'opacity-60 pointer-events-none'"
      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm"
    >
      {{$t("template.newFormBtn2")}}
    </button>
  </Modal>
</template>
<script>
import TemplateStep from "@/components/admin/FormTemplate/TemplateStep.vue";
import InputWithErrorVue from "@/components/inputs/InputWithError.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import FormTemplateForm from "@/components/admin/FormTemplate/TemplateForm.vue";
import DeviceTemplateForm from "@/components/admin/DeviceTemplate/TemplateForm.vue";
import Modal from "@/components/layout/Modal.vue";
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { mapState } from "vuex";
import { DocumentArrowDownIcon, ArrowPathIcon ,ArrowLeftIcon, PlusIcon, CheckIcon, HomeIcon, ClockIcon } from "@heroicons/vue/24/outline";

export default {
  components: {
    TemplateStep,
    InputWithErrorVue,
    RadioGroup,
    DocumentArrowDownIcon,
    ArrowPathIcon,
    ArrowLeftIcon,
    CheckIcon,
    PlusIcon,
    HomeIcon,
    ClockIcon,
    FormTemplateForm,
    DeviceTemplateForm,
    Modal
  },
  async mounted() {
    const sc = localStorage.getItem(`${this.formType}FormTemplateSchema`);
    if (sc && sc != "[]") this.$refs.modal.show();
    else this.fetchSchema()
  },
  data() {
    return {
      schema: [],
      value: [],
      templateToCopy: null
    };
  },
  computed: {
    ...mapState(["organisation", "deviceTemplates"]),
    formTemplateId() {
      return this.$route.params.formTemplateId
    },
    formType() {
      return this.$route.params.formTemplateId
    },
    deviceTemplate() {
      return this.deviceTemplates.find(template => {return template.id == this.$route.params.deviceTemplateId})
    },
    availableToCopy(){
      const availableToCopy = []

      if (this.deviceTemplate.versions.length > 1) {
        const previousVersion = this.deviceTemplate.versions.find(v => {return v.versionNumber == (this.deviceTemplate.activeVersion.versionNumber - 1)})
        if (previousVersion && previousVersion[`${this.formType}FormSchema`] && previousVersion[`${this.formType}FormSchema`].length) {
          availableToCopy.push({
            name: `${this.$t('template.formDescriptionText1')} ${this.formType == 'installation' ? this.$t('template.formDescriptionText2') : this.$t('template.formDescriptionText3')} - ${this.deviceTemplate.name}, v${previousVersion.versionNumber}`,
            type: this.formType,
            schema: previousVersion[`${this.formType}FormSchema`],
            recomended: true,
            id: previousVersion.id
          })
        }
      }
      this.deviceTemplates.forEach(template => {
        template.versions.forEach(version => {
          if (version.installationFormSchema && version.installationFormSchema.length) {
            if (!availableToCopy.find(template => {return template.type == 'installation' && template.id == version.id})) {
              availableToCopy.push({name: `${this.$t('template.formDescriptionText1')} ${this.$t('template.formDescriptionText2')} - ${template.name}, v${version.versionNumber}`, type: 'installation', schema: version.installationFormSchema, id: version.id})
            }
          }
          if (version.inspectionFormSchema && version.inspectionFormSchema.length) {
            if (!availableToCopy.find(template => {return template.type == 'inspection' && template.id == version.id})) {
              availableToCopy.push({name: `${this.$t('template.inspectionForm')} - ${template.name}, v${version.versionNumber}`, type: 'inspection', schema: version.inspectionFormSchema, id: version.id})
            }
          }
        })
      })
      return availableToCopy
    },
    device() {
      return {
        id: 666,
        step: 10,
        parameters: {},
        deviceTemplate: this.deviceTemplate
      }
    },
    allFields() {
      return this.schema.map((step, stepIndex) => {
        return step.schema.map(field => {return {...field, stepIndex: stepIndex}})
      }).flat(1)
    },
    areFieldNamesUnique() {
      // check if there are two objects in schema that have the same name

      for (var i = 0; i < this.allFields.length - 1; i++) {
        for (var j = i + 1; j < this.allFields.length; j++) {
          const nameI = ['image', 'signature'].includes(this.allFields[i].type) ? this.allFields[i].imageName : this.allFields[i].name
          const nameJ = ['image', 'signature'].includes(this.allFields[j].type) ? this.allFields[j].imageName : this.allFields[j].name
          if (nameI == nameJ)
            return false;
        }
      }
      return true;
    },
  },
  watch: {
    schema: {
      deep: true,
      handler(val) {
        console.log("schema changed to:", val);
        localStorage.setItem(`${this.formType}FormTemplateSchema`, JSON.stringify(val));
      },
    },
  },
  methods: {
    moveField (from, to) {
      this.schema.splice(to, 0, this.schema.splice(from, 1)[0]);
      this.value.splice(to, 0, this.value.splice(from, 1)[0]);
    },
    deleteField(index) {
      this.schema.splice(index, 1);
      this.value.splice(index, 1);
    },
    activateStep(step) {
      this.$refs.formTemplateForm.goToStep(step+1)
    },
    addStep() {
      this.schema.forEach(field => {field.unfolded = false})
      this.schema.push({
        unfolded: true,
        name: "",
        schema: [{
          unfolded: true,
          label: "",
          labels: {},
          name: "",
          placeholder: "",
          placeholders: {},
          imageLabel: "",
          imageLabels: {},
          imageName: "",
          type: "string",
          choices: [],
          conditionalLogic: [[]],
        }],
        conditionalLogic: [[]]
      });
      this.value.push({})
    },
    submit() {
      this.$notify({
        title: 'Test',
        text: `${this.$t('public.infos.submitFormSuccess')}`,
        type: 'success'
      })
    },
    invalid() {
      this.$notify({
        title: 'Test',
        text: `${this.$t('public.infos.invalidForm')}`,
        type: 'warning'
      })
    },
    sanitizeLogic(logic) {
      if (!logic) return [[]];
      const sanitizedLogic = [];
      logic.forEach(alternative => {
        const sanitizedAlternative = alternative.filter(condition => {
          if (!condition.field) return false
          const source = condition.field.split('.')[0]
          const name = condition.field.split('.')[1]
          const fields = source == 'form' ? this.allFields : this.deviceTemplate.activeVersion.parametersSchema
          const schemaField = fields.find(field => {return field.name == name})
          return condition.field && schemaField
        })
        if (sanitizedAlternative.length || !sanitizedLogic.length) sanitizedLogic.push(sanitizedAlternative)
      })
      return sanitizedLogic
    },
    fetchLocalStorage() {
      this.$refs.modal.close();
      const schema = localStorage.getItem(`${this.formType}FormTemplateSchema`);
      if (schema) this.schema = JSON.parse(schema);
    },
    fetchSchema() {
      this.$refs.modal.close();
      this.schema = this.deviceTemplate.activeVersion[`${this.formType}FormSchema`] || []
      this.value = this.schema.map(() => {return {validated: false}})
      if (this.schema.length == 0 && this.availableToCopy.length > 0) this.$refs.copymodal.show()
    },
    copySchema() {
      this.schema = this.templateToCopy.schema
      this.value = this.schema.map(() => {return {validated: false}})
      this.$refs.copymodal.close()
      this.$notify({
        title: this.$t('template.warning'),
        text: this.$t('template.conditionalLogicWarning'),
        type: "warning",
        duration: 10000
      })
    },
    wipeLocalStorage() {
      localStorage.removeItem(`${this.formType}FormTemplateSchema`);
    },
    possiblyGoBack() {
        if (confirm(this.$t("template.goBackSaveConfirm"))) {
          this.goBack(false);
        }
    },
    goBack(confirmationNeeded) {
      // console.log("testing route.params", this.$route.params);
      if (confirmationNeeded) {
        if (confirm(this.$t("template.goBackDiscardConfirm"))) {
          this.wipeLocalStorage();
          this.$router.push({name: 'admin-settings', hash: '#deviceTemplates'})
        }
      } else {
        this.$router.push({name: 'admin-settings', hash: '#deviceTemplates'})
      }
    },
    async sendData() {
      if (!this.areFieldNamesUnique) {
        this.$notify({
          title: this.$t('template.warning'),
          text: this.$t('template.sameFieldNamesWarning'),
          type: 'warning'
        })
        return
      }
      // creating the object to be sent
      let anyImageUnnamed = false
      this.schema.forEach(step => {
        step.schema.forEach(field => {
          if (['image', 'signature'].includes(field.type) && !field.imageName) {
            anyImageUnnamed += true
            field.unfolded = true
            step.unfolded = true
          } else {
            field.unfolded = false
            step.unfolded = false
          }
        })
      })
      if (anyImageUnnamed) {
        this.$notify({
          title: this.$t('template.warning'),
          text: this.$t('template.missingImageNamesWarning'),
          type: 'warning'
        })
        return
      }

      this.$store.dispatch("setLoading", true);

      const schemaArray = []
      this.schema.forEach((step) => {
        const stepSchema = step.schema.map(field => {
          const { label, labels, name, placeholder, placeholders, type, conditionalLogic } = field
          const obj = {
            label,
            labels,
            name,
            placeholder,
            placeholders,
            type,
            conditionalLogic: this.sanitizeLogic(conditionalLogic)
          }
          if (['image', 'signature'].includes(field.type)) {
            return { ...obj, imageName: field.imageName, imageLabel: field.imageLabel, imageLabels: field.imageLabels}
          } else if (field.type == "list") {
            return { ...obj, choices: field.choices}
          } else {
            return obj
          }
        })
        const sanitizedStep = {
          name: step.name,
          names: step.names,
          schema: stepSchema,
          conditionalLogic: this.sanitizeLogic(step.conditionalLogic)
        }
        schemaArray.push(sanitizedStep)
      });
      console.log("testing schema array:", schemaArray);

      // comment everything below for testing purposes
      // sending data
      const data = {}
      const key = `${this.formType}FormSchema`
      data[key] = schemaArray
      const id = this.deviceTemplate.activeVersion.id
      try {
        const result = await api.patch(`/device_template_schema_versions/${id}`, data, patchHeaders)
        console.log(result)
        this.wipeLocalStorage();
        await this.$store.dispatch("getDeviceTemplates", false);
        this.$store.dispatch("setLoading", false);
        this.goBack(false);
      } catch (error) {
        console.log(error);
        this.$store.dispatch("setLoading", false);
      }
    },
    clearAllData() {
      if (confirm(`${this.$t("template.clearDataConfirm")}`)) {
        this.wipeLocalStorage();
        this.schema = [];
      }
    }
  },
};
</script>
