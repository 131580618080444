<template>
  <div class="block text-sm mb-2 font-medium text-gray-700">{{$t("template.dataTableHeading")}}</div>
  <div class="inline-block relative flex items-start py-1 mb-2">
    <div class="flex h-5 items-center">
      <input
        id="showDataTable"
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-ifgreen focus:ring-ifgreen"
        v-model="schema.showDataTable"
      />
    </div>
    <label for="showDataTable" class="ml-2 text-sm text-gray-700">{{$t("template.addTableAutomatically")}}</label>
  </div>
  <CheckboxGroup 
    :choices="availableParameters"
    v-model="schema.tableParameters"
    :dropdown="false"
  />
  <div class="block text-sm mt-8 mb-2 font-medium text-gray-700">{{$t("template.warrantyContent")}}</div>
  <div v-if="editorVisible">
    <Editor 
      api-key="s0uxlejjhpbkcwpi55z59r5sn0z4ewvvxnv3phbitmt9ol1u"
      v-model="schema.content"
      ref="editor"
      :init="{
        height: '500',
        language: 'pl',
        menubar: false,
        plugins: 'lists link template noneditable table',
        toolbar: 'undo redo | styleselect | alignleft aligncenter alignright | bold italic underline | link | bullist numlist | template | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        style_formats: [
          {title: 'Nagłowek',format: 'h5'},
          {title: 'Paragraf', format: 'p'}
        ],
        templates: variables,
      }"
      @keyUp="handleInput"
    />
  </div>
  <div class="block text-sm mt-8 mb-2 font-medium text-gray-700">{{$t("template.serviceLog")}}</div>
  <div class="inline-block relative flex items-start py-1">
    <div class="flex h-5 items-center">
      <input
        id="showServiceLog"
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-ifgreen focus:ring-ifgreen"
        v-model="schema.showServiceLog"
      />
    </div>
    <label for="showServiceLog" class="ml-2 text-sm text-gray-700">{{$t("template.attachServiceLog")}}</label>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import CheckboxGroup from '@/components/inputs/CheckboxGroup.vue'
import { mapState } from 'vuex';

export default {
  components: {
    Editor,
    CheckboxGroup
  },
  emits: ['update:modelValue'],
  props: { 
    modelValue: Array,
    deviceTemplate: Object,
    dummyInstallation: Object,
    language: String
  },
  mounted() {
  },
  data() {
    return {
      schema: {
        showDataTable: false,
        tableParameters: [],
        content: "",
        showServiceLog: false,
      },
      editorVisible: true,
    };
  },
  computed: {
    ...mapState(["organisation", "user"]),
    pagebreak () {
      return {
        title: this.$t('template.pageBreakTitle'),
        description: this.$t('template.pageBreakDescription'),
        content: `<div style="height: 0; text-align: center;" class="pdf-pagebreak-before mceNonEditable">{{pagebreak}}</div>`
      }
    },
    deviceVariables() {
      return this.deviceTemplate.activeVersion.parametersSchema.map(field => {
        const label = field.labels?.[this.$i18n.locale] ? field.labels[this.$i18n.locale] : field.label
        return {
          title: label,
          description: `${this.$t('template.addsVariable')} ${label}`,
          content: `<span class="mceNonEditable">{{${field.name}}}</span>`
        }
      })
    },
    installerVariables() {
      const labels = {
        'name': this.$t('template.installerName'),
        'company': this.$t('template.installerCompany'),
        'tin': this.$t('template.installerTin'),
        'email' : this.$t('template.installerEmail'),
        'phoneNumber' : this.$t('template.installerPhone')
      }
      return Object.entries(labels).map(entry => {
        return {
          title: entry[1],
          description: `${this.$t('template.addsVariable')} ${entry[1]}`,
          content: `<span class="mceNonEditable">{{installer.${entry[0]}}}</span>`
        }
      })
    },
    installationVariables() {
      const labels = {
        'customerEmail': this.$t('template.customerEmail'),
        'customerName': this.$t('template.customerName'),
        'customerCompany': this.$t('template.customerCompany'),
        'customerAddress': this.$t('template.customerAddress'),
        'customerCity': this.$t('template.customerCity'),
        'customerZipcode': this.$t('template.customerZipcode'),
        'customerPhone': this.$t('template.customerPhone'),
        'installationDate': this.$t('template.installationDate')
      }
      return Object.entries(labels).map(entry => {
        return {
          title: entry[1],
          description: `${this.$t('template.addsVariable')} ${entry[1]}`,
          content: `<span class="mceNonEditable">{{installation.${entry[0]}}}</span>`
        }
      })
    },
    signatures () {
      const signatures = []
      this.deviceTemplate.activeVersion.installationFormSchema.forEach(step => {
        signatures.push(...step.schema.filter(field => {return field.type == 'signature'}).map(field => {
          const imageLabel = field.imageLabels?.[this.$i18n.locale] ? field.imageLabels[this.$i18n.locale] : field.imageLabel
          return {
            title: imageLabel,
            description: `${this.$t('template.addsVariable')} ${imageLabel}`,
            content: `<div style="text-align: right">
              <img style="width: 200px" src="{{signature.${field.imageName}}}" /> 
              <p class="small">${field.labels?.[this.language] ? field.labels[this.language] : field.label}<br>{{installation.installationDate}}</p>
            </div>`
          }
        }))
      })
      return signatures
    },
    variables(){
      return [this.pagebreak, ...this.deviceVariables, ...this.installerVariables, ...this.installationVariables, ...this.signatures]
    },
    availableParameters () {
      return this.deviceTemplate.activeVersion.parametersSchema.map(field => {
        return {
          name: field.labels?.[this.$i18n.locale] ? field.labels[this.$i18n.locale] : field.label, 
          value: field.name}
      })
    },
  },
  methods: {
    handleInput(e, editor) {
      const rootNodes = editor.dom.select('ol>li')
      if (rootNodes.length) editor.dom.setStyle(rootNodes, 'margin', '0 0 10px 0'); 
      const olnodes = editor.dom.select('ol ol>li')
      const ulnodes = editor.dom.select('ol ul>li')
      if (olnodes.length) editor.dom.setStyle(olnodes, 'margin', '0'); 
      if (ulnodes.length) editor.dom.setStyle(ulnodes, 'margin', '0'); 
    }
  },
  watch: {
    // reinit editor after lang change to refresh templates
    language () {
      this.editorVisible = false
      this.$nextTick(() => {
        this.editorVisible = true
      })
    },
    "$i18n.locale": {
      handler () {
        this.editorVisible = false
        this.$nextTick(() => {
          this.editorVisible = true
        })
      }
    },
    modelValue: {
      immediate: true,
      deep: true,
      handler () {
        this.schema = this.modelValue
      }
    },
    schema: {
      deep: true,
      handler () {
        this.$emit('update:modelValue', this.schema)
      }
    },
  }
};
</script>
