<template>
  <TemplateField
    v-model="value.type"
    :index="fieldIndex"
    :schemaLength="stepSchemaLength"
    :fieldTypes="fieldTypes"
    :unfolded="value.unfolded"
    @toggle-unfolded="value.unfolded = !value.unfolded"
    @move-up="$emit('move-up')"
    @move-down="$emit('move-down')"
    @delete="$emit('delete')"
  >
    <div class="-mx-4 sm:flex sm:justify-between">
      <div class="px-4 w-1/2">
        <div class="relative">
          <InputWithErrorVue
            v-model="value.label"
            @update:modelValue="updateName('label', 'name')"
            :lazy="true"
            type="textarea"
            :label="$t('template.fieldDescription')"
            :placeholder="$t('template.fieldDescriptionPlaceholder')"
          />
          <TranslationInput  
            v-if="value.label != ''"
            v-model="value.labels"
            :mainLanguage="mainLanguage" 
            :translatedValue="value.label" 
            buttonClasses="top-7 right-1"
          />
        </div>
      </div>
      <div
        v-show="['string', 'image', 'signature'].includes(value.type)"
        class="mt-2 px-4 w-1/2 sm:mt-0"
      >
        <div class="relative">
          <InputWithErrorVue
            v-model="value.placeholder"
            type="text"
            label="Placeholder"
            :placeholder="$t('add') + ` placeholder`"
          />
          <TranslationInput  
            v-if="value.placeholder != ''"
            v-model="value.placeholders"
            :mainLanguage="mainLanguage" 
            :translatedValue="value.placeholder" 
            buttonClasses="top-7 right-1"
          />
        </div>
        <div class="relative">
          <InputWithErrorVue
            v-if="value.type == 'image' || value.type == 'signature'"
            v-model="value.imageLabel"
            @update:modelValue="updateName('imageLabel', 'imageName', '-')"
            type="text"
            :label="value.type == 'image' ? $t('template.imageName') : $t('template.signatureName')"
            :placeholder="value.type == 'image' ? $t('template.imagePlaceholder') : $t('template.signaturePlaceholder')"
            :validation="v$.value.imageName"
            class="mt-2"
          />
          <TranslationInput  
            v-if="value.imageLabel"
            v-model="value.imageLabels"
            :mainLanguage="mainLanguage" 
            :translatedValue="value.imageLabel" 
            buttonClasses="top-7 right-1"
          />
        </div>
      </div>
      <div v-show="value.type == 'list'" class="mt-2 px-4 w-1/2 flex flex-col justify-start items-end sm:mt-0">
        <ListOptionsEditor
          :name="value.name"
          :mainLanguage="mainLanguage"
          v-model="value.choices"
        />
      </div>
    </div>
    <TemplateFieldConditional
      v-model="value.conditionalLogic"
      type="field"
      :formFields="formFields"
      :deviceFields="deviceFields"
      :stepIndex="stepIndex"
      :totalFieldIndex="totalFieldIndex"
      :areFieldNamesUnique="areFieldNamesUnique"
    />
  </TemplateField>
</template>
<script>

import TemplateField from "@/components/layout/TemplateField.vue";
import TemplateFieldConditional from "@/components/admin/FormTemplate/TemplateFieldConditional.vue";
import InputWithErrorVue from "@/components/inputs/InputWithError.vue";
import TranslationInput from "../../inputs/TranslationInput.vue";
import ListOptionsEditor from "@/components/inputs/ListOptionsEditor.vue";
import slugify from 'slugify'
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  components: {
    TemplateField,
    TemplateFieldConditional,
    InputWithErrorVue,
    TranslationInput,
    ListOptionsEditor,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  emits: [
    'update:modelValue',
    'move-up',
    'move-down',
    'delete'
  ],
  props: {
    modelValue: Object,
    fieldIndex: Number,
    stepIndex: Number,
    stepSchemaLength: Number,
    areFieldNamesUnique: Boolean,
    formFields: Array,
    deviceFields: Array,
    mainLanguage: String,
  },
  data () {
    return {
      value: {
        unfolded: false,
        label: "",
        labels: {},
        name: "",
        placeholder: "",
        placeholders: {},
        imageLabel: "",
        imageLabels: {},
        imageName: "",
        type: "string",
        choices: [],
        conditionalLogic: [[]],
      }
    }
  },
  validations() {
    return {
      value: {
        imageName: {
          required: helpers.withMessage(`${this.$t("template.reqField")}`, required)
        }
      }
    }
  },
  computed: {
    slugifySettings () {
      return {
        replacement: '_',  // replace spaces with replacement character, defaults to `-`
        remove: undefined, // remove characters that match regex, defaults to `undefined`
        lower: true,      // convert to lower case, defaults to `false`
        strict: true,     // strip special characters except replacement, defaults to `false`
        locale: 'pl',       // language code of the locale to use
        trim: true         // trim leading and trailing replacement chars, defaults to `true`
      }
    },
    fieldTypes () {
      return [
        {value: 'string', name: `${this.$t("template.text")}`},
        {value: 'list', name: `${this.$t("template.list")}`},
        {value: 'yesno', name: `${this.$t("template.yesNo")}`},
        {value: 'image', name: `${this.$t("template.image")}`},
        {value: 'signature', name: `${this.$t("template.signature")}`}
      ]
    },
    totalFieldIndex() {
      return this.formFields.findIndex(field => {return field.name == this.value.name})
    }
  },
  methods: {
    updateName(label, name, replacement = '_') {
      const settings = this.slugifySettings
      settings.replacement = replacement
      this.value[name] = slugify(this.value[label], settings)
    },
    toggleBarcode(event) {
      this.value.barcodeScanner = event.state;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler () {
        this.value = this.modelValue
        if (this.value.type == 'image' && this.v$.$touch) this.v$.$touch()
        if (this.value.type == 'list' && !this.value.choices) this.value.choices = []
      }
    },
    value: {
      deep: true,
      handler () {
        this.$emit('update:modelValue', this.value)
      }
    },
  }

};
</script>
