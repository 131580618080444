<template>
  <TemplateField
    v-model="value.type"
    :index="index"
    :schemaLength="schema.length"
    :fieldTypes="fieldTypes"
    :unfolded="value.unfolded"
    :label="value.name || value.file?.name"
    @toggle-unfolded="value.unfolded = !value.unfolded"
    @move-up="$emit('move-up')"
    @move-down="$emit('move-down')"
    @delete="$emit('delete')"
  >
    <div v-show="value.type == 'internal'" class="-mx-2">
      <div class="px-2 w-full">
        <FileSelect
          v-model="value.file"
          :files="files"
          :label="$t('settings.chooseFile')"
        />
      </div>
    </div>
    <div v-show="value.type == 'external'" class="-mx-2 sm:flex sm:justify-between">
      <div class="px-2 w-1/3">
        <InputWithErrorVue
          v-model.lazy="value.name"
          :lazy="true"
          :id="'name-' + index"
          :name="'name-' + index"
          type="text"
          :label="$t('name')"
          :placeholder="$t('name')"
          errorText=""
        />
      </div>
      <div class="px-2 w-2/3">
        <InputWithErrorVue
          v-model="value.path"
          :id="'url-' + index"
          :name="'url-' + index"
          type="url"
          label="Url"
          placeholder="https://..."
          errorText=""
          :validation="v$.value.path"
        />
      </div>
    </div>
    <WarrantyAttachmentConditional
      v-model="value.conditionalLogic"
      :schema="schema"
    />
  </TemplateField>
</template>
<script>

import TemplateField from "@/components/layout/TemplateField.vue";
import WarrantyAttachmentConditional from "@/components/admin/WarrantyTemplate/WarrantyAttachmentConditional.vue";
import InputWithErrorVue from "@/components/inputs/InputWithError.vue";
import FileSelect from "@/components/inputs/FileSelect.vue";
import useVuelidate from "@vuelidate/core";
import { url, helpers } from "@vuelidate/validators";
export default {
  components: {
    TemplateField,
    WarrantyAttachmentConditional,
    InputWithErrorVue,
    FileSelect
  },
  emits: [
    'update:modelValue',
    'move-up',
    'move-down',
    'delete'
  ],
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    modelValue: Object,
    index: Number,
    areFieldNamesUnique: Boolean,
    schema: Array,
    files: Array
  },
  data () {
    return {
      value: {
        unfolded: false,
        name: "",
        path: "",
        type: "internal",
        file: null,
        conditionalLogic: [[]],
      }
    }
  },
  validations() {
    return {
      value: {
        path: {
          url: helpers.withMessage("Wpisz prawidłowy adres url", url),
        }
      }
    }
  },
  computed: {
    fieldTypes () {
      return [
        {value: 'internal', name: this.$t("template.internal")},
        {value: 'external', name: this.$t("template.external")}
      ]
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler () {
        this.value = this.modelValue
        if (this.value.type == 'list' && !this.value.choices) this.value.choices = []
      }
    },
    value: {
      deep: true,
      handler () {
        this.$emit('update:modelValue', this.value)
      }
    },
  }

};
</script>
