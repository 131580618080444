<template>
  <li @click="$emit('step-click', stepIndex)">
    <div class="px-4 py-4 sm:px-6 block">
      <div class="flex items-center justify-between">
        <div class="flex items-baseline">
          <div class="group flex items-center">
            <span class="flex items-center text-sm font-medium">
              <span class="flex-shrink-0 w-16 h-16 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                <span class="text-xl text-gray-500 group-hover:text-gray-900">{{ formatedStepNumber }}</span>
              </span>
              <span class="ml-4 min-w-0 space-y-1 flex flex-col relative">
                <InputWithError
                  v-model="step.name"
                  :lazy="true"
                  :placeholder="$t('template.stepName')"
                />
                <TranslationInput
                  v-if="step.name" 
                  :mainLanguage="mainLanguage"
                  :translatedValue="step.name"
                  v-model="step.names"
                  buttonClasses="-right-8 top-1.5"
                />
              </span>
            </span>
          </div>
        </div>
        <div class="ml-4 flex-shrink-0 relative z-0 inline-flex shadow-sm rounded-md">
          <button @click="step.unfolded = !step.unfolded" :title="step.unfolded ? $t('collapse') : $t('expand')" type="button" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
            <ChevronUpIcon v-if="step.unfolded" class="h-5 w-5" aria-hidden="true" />
            <ChevronDownIcon v-else class="h-5 w-5" aria-hidden="true" />
          </button>
          <button v-if="stepIndex != 0" @click="$emit('move-up')" :title="$t('moveUp')" type="button" class="relative inline-flex items-center px-2 py-2 -ml-px border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
            <ArrowUpIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button v-if="stepIndex < (schemaLength - 1)" @click="$emit('move-down')" :title="$t('moveDown')" type="button" class="relative inline-flex items-center px-2 py-2 -ml-px border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
            <ArrowDownIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button :title="$t('template.deleteField')" @click="$emit('delete')" type="button" id="edit-icon" :class="['-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
            <TrashIcon class='h-5 w-5' aria-hidden="true" />
          </button>
        </div>
      </div>
      <div v-show="step.unfolded" class="mt-4">
        <div class="block text-sm font-medium text-gray-700 mt-5">{{$t("template.fields")}}</div>
        <ul v-if="step.schema.length" class="mt-1 divide-y divide-gray-200 rounded-md shadow border border-gray-200">
          <TemplateField
            v-for="(item, index) in step.schema"
            v-model="step.schema[index]"
            :key="index"
            :fieldIndex="index"
            :stepIndex="stepIndex"
            :stepSchemaLength="step.schema.length"
            :formFields="formFields"
            :deviceFields="deviceFields"
            :areFieldNamesUnique="areFieldNamesUnique"
            :mainLanguage="mainLanguage"
            @move-up="moveField(index, index-1)"
            @move-down="moveField(index, index+1)"
            @delete="deleteField(index)"
          />
        </ul>
        <button
          class="block bg-ifgreen hover:bg-ifgreen-dark text-white mt-2 w-full rounded-md"
          @click="addField"
        >
          <div class="px-3 py-3 flex justify-center">
            <PlusIcon class="h-6 w-6 mr-2" aria-hidden="true" /> {{$t("template.addField")}}
          </div>
        </button>
        <TemplateFieldConditional
          v-model="step.conditionalLogic"
          type="step"
          :formFields="formFields"
          :deviceFields="deviceFields"
          :stepIndex="stepIndex"
          :areFieldNamesUnique="areFieldNamesUnique"
        />
      </div>
    </div>
  </li>
</template>
<script>

import TranslationInput from "@/components/inputs/TranslationInput.vue";
import TemplateField from "@/components/admin/FormTemplate/TemplateField.vue";
import TemplateFieldConditional from "@/components/admin/FormTemplate/TemplateFieldConditional.vue";
import InputWithError from "@/components/inputs/InputWithError.vue";
import { ChevronUpIcon, ChevronDownIcon, ArrowUpIcon, ArrowDownIcon, PlusIcon, TrashIcon } from "@heroicons/vue/24/outline";
export default {
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    TrashIcon,
    PlusIcon,
    TemplateField,
    TemplateFieldConditional,
    InputWithError,
    TranslationInput
},
  emits: [
    'update:modelValue',
    'move-up',
    'move-down',
    'delete'
  ],
  props: {
    modelValue: Object,
    stepIndex: Number,
    schemaLength: Number,
    areFieldNamesUnique: Boolean,
    formFields: Array,
    deviceFields: Array,
    mainLanguage: String
  },
  data () {
    return {
      step: {
        unfolded: true,
        name: "Test",
        names: {},
        schema: [],
        conditionalLogic: [[]]
      }
    }
  },
  computed: {
    formatedStepNumber () {
      return this.stepIndex < 9 ? `0${this.stepIndex+1}` : this.stepIndex+1
    }
  },
  methods: {
    addField() {
      this.step.schema.forEach(field => {field.unfolded = false})
      this.step.schema.push({
        unfolded: true,
        label: "",
        name: "",
        type: "string",
        barcodeScanner: true,
        choices: [],
        conditionalLogic: [[]],
      });
    },
    moveField (from, to) {
      this.step.schema.splice(to, 0, this.step.schema.splice(from, 1)[0]);
    },
    deleteField(index) {
      this.step.schema.splice(index, 1);
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler () {
        this.step = this.modelValue
      }
    },
    step: {
      deep: true,
      handler () {
        this.$emit('update:modelValue', this.step)
      }
    },
  }

};
</script>
