<template>
  <ul v-if="schema.length && files" role="list" class="mt-1 divide-y divide-gray-200 rounded-md shadow border border-gray-200">
    <WarrantyAttachment
      v-for="(item, index) in schema"
      v-model="schema[index]"
      :key="item.name"
      :index="index"
      :schema="deviceParametersSchema"
      :areFieldNamesUnique="true"
      :files="files"
      @move-up="moveField(index, index-1)"
      @move-down="moveField(index, index+1)"
      @delete="deleteField(index)"
    />
  </ul>
  <button
    class="block bg-ifgreen hover:bg-ifgreen-dark text-white mt-2 w-full rounded-md"
    @click="addAttachment"
  >
    <div class="px-3 py-3 flex justify-center">
      <PlusIcon class="h-6 w-6 mr-2" aria-hidden="true" /> {{$t("template.addAttachments")}}
    </div>
  </button>
</template>
<script>
import WarrantyAttachment from "@/components/admin/WarrantyTemplate/WarrantyAttachment.vue";
import { mapState } from "vuex";
import { api } from '@/assets/js/api-client.js'
import { ArrowPathIcon ,ArrowLeftIcon, PlusIcon, CheckIcon } from "@heroicons/vue/24/outline";

export default {
  components: {
    WarrantyAttachment,
    ArrowPathIcon,
    ArrowLeftIcon,
    CheckIcon,
    PlusIcon,
  },
  emits: ['update:modelValue'],
  props: { 
    modelValue: Array,
    deviceParametersSchema: Array,
    language: String 
  },
  mounted() {
    this.getFiles()
    // if needed, open the modal to fetch the template from local storage instead
  },
  data() {
    return {
      schema: [],
      files: null
    };
  },
  computed: {
    ...mapState(["organisation"]),
  },
  methods: {
    getFiles(){
      api.get(`/organisations/${this.organisation}/files?language=${this.language}`).then(result => {
        console.log(result)
        this.files = result.data
      }).catch(error => {
        console.log(error)
      })
    },
    moveField (from, to) {
      this.schema.splice(to, 0, this.schema.splice(from, 1)[0]);
    },
    deleteField(index) {
      this.schema.splice(index, 1);
    },
    addAttachment() {
      this.schema.forEach(field => {field.unfolded = false})
      this.schema.push({
        unfolded: true,
        name: "",
        path: "",
        type: "internal",
        file: null,
        conditionalLogic: [[]],
      });
    },
    sanitizeLogic(logic) {
      if (!logic) return [[]];
      const sanitizedLogic = [];
      logic.forEach(alternative => {
        const sanitizedAlternative = alternative.filter(condition => {return condition.field})
        if (sanitizedAlternative.length || !sanitizedLogic.length) sanitizedLogic.push(sanitizedAlternative)
      })
      return sanitizedLogic
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler () {
        this.schema = this.modelValue
      }
    },
    schema: {
      deep: true,
      handler () {
        this.$emit('update:modelValue', this.schema)
      }
    },
    language() {
      this.getFiles()
    }
  }
};
</script>
